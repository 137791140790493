import Layout from '@components/layout';
import React from 'react';
import { Link } from 'gatsby';

export default function healthcare(props) {
  return (
    <Layout {...props}>
      <div className="hero hero-2 solutions-hero">
        <div className="hero-2__background" />
        <div className="hero-2__container" />
        <div className="solutions-hero__wrapper">
          <div className="solutions-hero__pattern">
            <img src="/images/case-hero-pattern.png" alt="" />
          </div>

          <div className="solutions-hero__background">
            <h2 class="h2-label">
              <div class="h2-label__title">Experience</div>
              <div class="h2-label__seperator"></div>
            </h2>
            <img src="/images/healthcare-banner.jpeg" />
          </div>
        </div>
      </div>
      <section className="solutions-content">
        <div className="long-content__wrapper">
          <h2 className="solution-section__header">
            Custom Software Solutions for the Healthcare Industry: Innovate, Improve, and Inspire
          </h2>
          <p className="solution-section__description">
            In an era defined by digitization, the healthcare industry is at a transformative stage. Our custom software solutions are specifically designed to streamline your operations, help providers innovate, enhance patient care, and ultimately boost your bottom line. We understand that each healthcare organization is unique, and therefore, requires a tailor-made approach.
          </p>

          <h2 className="solution-section__header">
            Driving Innovation in Healthcare
          </h2>
          <p className="solution-section__description">
            Innovation is the key to navigating the complexities of the healthcare industry. Our software solutions are designed to foster innovation by:
          </p>
          <ul>
            <li>
              <span>
                <b>Integrating cutting-edge technologies:</b> We can integrate AI, machine learning, blockchain or other technologies into your systems to enhance services and create new possibilities for patients, like having Electronic Health Records, Clinical Decision support systems, and more.
              </span>
            </li>
            <li>
              <span>
                <b>Data-driven decisions: </b> Software can help you harness the power of big data by analyzing laboratory test results, medical records, etc, thus providing insights that drive informed decision-making and improve patient outcomes.
              </span>
            </li>
            <li>
              <span>
                <b>Improving accessibility:</b> Through telemedicine solutions, we can extend your reach, making healthcare accessible to patients anytime, anywhere.
              </span>
            </li>
            <li>
              <span>
                <b>Supporting research: </b> With efficient data management and analytics, our software solutions can support clinical research and help advance medical knowledge.
              </span>
            </li>
          </ul>

          <h2>
            Benefits of Custom Software in Healthcare
          </h2>

          <div className="solution-cards__group">
            <SolutionCard title="Efficiency">
              We can automate routine tasks, reduce paperwork, and speed up processing times. This leads to significant time savings and allows healthcare professionals to focus on what they matter most - patient care.
            </SolutionCard>
            <SolutionCard title="Data Security">
              The secure handling of sensitive patient data is crucial. Our custom software is designed with the highest security standards in mind, ensuring your data is always protected.
            </SolutionCard>
            <SolutionCard title="Scalability">
              A tailor-made software solution can be designed with the ability
              to scale up or down as the needs of the healthcare service change.
              This can help to ensure that the software is able to meet the
              needs of the service now and in the future.
            </SolutionCard>
            <SolutionCard title="Interoperability">
              Our custom software enables seamless data exchange between different systems, improving collaboration and decision-making.
            </SolutionCard>
            <SolutionCard title="Patient Engagement">
                Our solutions can help improve patient engagement with easy-to-use interfaces, personalized health tracking, and accessible medical records.
            </SolutionCard>
          </div>

          <h2 className="solution-section__header">
            Why Choose Us
          </h2>
          <p className="solution-section__description">
            We are not just software developers. We are your partners in transformation. We combine our technical expertise with a deep understanding of the healthcare industry to deliver solutions that truly make a difference.
          <br />          <br />  
            Embrace the future of healthcare with custom software solutions.
          </p>
        </div>
      </section>
    </Layout>
  );
}

function SolutionCard({ title, children }) {
  return (
    <div className="solution__card">
      <h3>{title}</h3>
      <p>{children}</p>
    </div>
  );
}
